import React from 'react'

const termsOfUse = () => {
    return (
        <section className="terms-of-use" datanav="black">
            <div className="wrap-title">
                <h2 className="title-terms">Terms of Use</h2>
            </div>

            <div className="wrap-description">
                <p className="description-terms">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Tellus orci ac auctor augue mauris augue neque
                    gravida in. In pellentesque massa placerat duis. Consequat
                    semper viverra nam libero justo laoreet sit. Nulla pharetra
                    diam sit amet nisl suscipit adipiscing. Imperdiet dui
                    accumsan sit amet. Odio eu feugiat pretium nibh ipsum
                    consequat nisl vel. Consectetur purus ut faucibus pulvinar
                    elementum integer enim. Egestas maecenas pharetra convallis
                    posuere morbi leo urna molestie. Dictumst quisque sagittis
                    purus sit amet volutpat. Pellentesque diam volutpat commodo
                    sed. Lectus quam id leo in vitae turpis massa sed elementum.
                    Pharetra pharetra massa massa ultricies mi quis hendrerit.
                    Justo laoreet sit amet cursus sit amet dictum sit.
                </p>

                <p className="description-terms">
                    Enim diam vulputate ut pharetra sit amet. Cras semper auctor
                    neque vitae tempus quam pellentesque nec. Orci eu lobortis
                    elementum nibh tellus molestie nunc non blandit. Tellus in
                    hac habitasse platea dictumst vestibulum rhoncus est
                    pellentesque. Eu consequat ac felis donec et odio
                    pellentesque. Feugiat in ante metus dictum at tempor commodo
                    ullamcorper a. Ut morbi tincidunt augue interdum. Consequat
                    mauris nunc congue nisi vitae suscipit tellus. Suspendisse
                    interdum consectetur libero id. Mattis vulputate enim nulla
                    aliquet porttitor lacus luctus accumsan tortor. Ut porttitor
                    leo a diam sollicitudin. Velit scelerisque in dictum non
                    consectetur a erat nam. Et malesuada fames ac turpis egestas
                    sed. Elit at imperdiet dui accumsan sit amet nulla. Urna
                    cursus eget nunc scelerisque viverra. Tincidunt arcu non
                    sodales neque sodales. Id venenatis a condimentum vitae
                    sapien pellentesque habitant morbi. Integer enim neque
                    volutpat ac tincidunt vitae semper quis lectus. Purus semper
                    eget duis at tellus at urna condimentum. Odio tempor orci
                    dapibus ultrices in. Turpis massa sed elementum tempus. Est
                    ullamcorper eget nulla facilisi etiam dignissim diam quis
                    enim. Velit scelerisque in dictum non consectetur a erat
                    nam. Sem integer vitae justo eget magna fermentum iaculis.
                    Eget est lorem ipsum dolor sit amet consectetur adipiscing
                    elit. Ornare massa eget egestas purus viverra. Senectus et
                    netus et malesuada fames. Porttitor rhoncus dolor purus non
                    enim praesent elementum facilisis leo. Elit ullamcorper
                    dignissim cras tincidunt lobortis feugiat vivamus. Arcu
                    dictum varius duis at consectetur lorem donec massa.
                    Tincidunt dui ut ornare lectus sit amet est placerat.
                </p>
                <p className="description-terms">
                    Semper eget duis at tellus at. At tellus at urna condimentum
                    mattis pellentesque. Quam elementum pulvinar etiam non quam.
                    Odio ut sem nulla pharetra diam. Bibendum enim facilisis
                    gravida neque convallis a cras semper. Egestas tellus rutrum
                    tellus pellentesque eu tincidunt tortor aliquam nulla.
                    Molestie nunc non blandit massa enim nec dui nunc. Ut tortor
                    pretium viverra suspendisse potenti nullam ac tortor vitae.
                    Velit scelerisque in dictum non. Massa id neque aliquam
                    vestibulum. Integer malesuada nunc vel risus. Adipiscing
                    elit pellentesque habitant morbi. In mollis nunc sed id
                    semper risus in. Adipiscing elit duis tristique sollicitudin
                    nibh sit amet commodo. Lorem sed risus ultricies tristique
                    nulla aliquet enim. Vulputate enim nulla aliquet porttitor
                    lacus luctus accumsan tortor posuere. Cras sed felis eget
                    velit aliquet sagittis. Sit amet luctus venenatis lectus
                    magna fringilla. Lobortis feugiat vivamus at augue eget arcu
                    dictum varius. Non nisi est sit amet facilisis.
                </p>
                <p className="description-terms">
                    Ornare lectus sit amet est placerat in. Purus in massa
                    tempor nec feugiat nisl pretium fusce id. Laoreet
                    suspendisse interdum consectetur libero id faucibus nisl
                    tincidunt. Sapien et ligula ullamcorper malesuada proin.
                    Rutrum tellus pellentesque eu tincidunt tortor aliquam nulla
                    facilisi cras. Nec nam aliquam sem et tortor consequat id
                    porta nibh. Enim nulla aliquet porttitor lacus luctus
                    accumsan tortor posuere. Amet facilisis magna etiam tempor
                    orci eu. Placerat vestibulum lectus mauris ultrices.
                    Faucibus et molestie ac feugiat sed lectus vestibulum mattis
                    ullamcorper. Vel pretium lectus quam id leo. Sit amet justo
                    donec enim diam vulputate. Fermentum leo vel orci porta non
                    pulvinar neque laoreet suspendisse. Ut tristique et egestas
                    quis ipsum suspendisse ultrices. At in tellus integer
                    feugiat scelerisque. Tempor commodo ullamcorper a lacus
                    vestibulum sed arcu non. Aliquam purus sit amet luctus
                    venenatis. Sit amet purus gravida quis blandit turpis cursus
                    in. Odio morbi quis commodo odio aenean.
                </p>
            </div>
        </section>
    )
}
export default termsOfUse
