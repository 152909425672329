import React from 'react'
import Layout from '../components/layout'
import Blog from '../components/blog'
import SEO from '../components/seo'
import Terms from '../components/terms/termsOfUse'
import ThemeContext from '../context/theme-context'

class TermsPage extends React.Component {
    componentDidMount() {
        const { closeMenu } = this.context
        closeMenu()
    }

    render() {
        const { isMobile, darkMode, currentLocale, intl } = this.context

        return (
            <Layout
                showNavLinks={true}
                initialMenuToggle={true}
                hideContact={isMobile}
            >
                <SEO title="Terms of Use" />

                <Terms />

                <Blog currentLocale={currentLocale} isMobile={isMobile} />
            </Layout>
        )
    }
}

TermsPage.contextType = ThemeContext

export default TermsPage
